import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import LoadingSpinnerPage from "./components/structure/common/LoadingSpinnerPage.js";
import { isTestnetPath } from "./lib/helper.js";

const StartContainer = lazy(() => import("./components/content/start/StartContainer.js"));
const ComicContainer = lazy(() => import("./components/content/comic/ComicContainer.js"));

const FeeReducerContainer = lazy(() => import("./components/content/feeReducer/FeeReducerContainer.js"));
const MyTokenListContainer = lazy(() => import("./components/content/myTokens/MyTokenListContainer.js"));
const StakingContainer = lazy(() => import("./components/content/staking/StakingContainer.js"));

const NftMintContainer = lazy(() => import("./components/content/nftMint/NftMintContainer.js"));
const NftListContainer = lazy(() => import("./components/content/nftList/NftListContainer.js"));
const NftMarketplaceContainer = lazy(() => import("./components/content/nftMarketplace/NftMarketplaceContainer.js"));

const NotFoundContainer = lazy(() => import("./components/content/notFound/NotFoundContainer.js"));

export default function App() {
    return (
		<BrowserRouter>
			<Suspense fallback={<LoadingSpinnerPage/>}>
				<Routes>
					<Route exact path="/" element={<StartContainer/>}/>
					<Route exact path="/comic" element={<ComicContainer/>}/>

					<Route exact path="/dapps/feereducer" element={<FeeReducerContainer/>}/>
					<Route exact path="/dapps/mytokens" element={<MyTokenListContainer/>}/>
					<Route exact path="/dapps/staking" element={<StakingContainer/>}/>

					<Route exact path="/nfts/mint" element={<NftMintContainer/>}/>
					<Route exact path="/nfts/list" element={<NftListContainer/>}/>

					{
						isTestnetPath() &&
						<Route exact path="/nfts/marketplace" element={<NftMarketplaceContainer/>}/>
					}

					<Route path="*" element={<NotFoundContainer/>}/>
				</Routes>
			</Suspense>
		</BrowserRouter>
    );
}